// TODO: Create account
import React from "react";
import styled from "styled-components";

// Utils
import { rem, Color } from "../../utils/style";
import { createAccount } from "../../utils/authenticate";
import { redirectReplaceHistory } from "../../utils/redirect";
import { emailRegex } from "../../utils/helpers";
import metrics from "../../utils/metrics";
import intl from "../../services/intl";

// Components
import RitualSubmitButton from "../global/RitualSubmitButton";
import Text from "../Text";

// Redux
import { connect } from "react-redux";
import cartSelectors from "../../store/cart/selectors";

// Styled Elements
const Form = styled.form.attrs({
  name: "create",
})`
  legend {
    display: none;
  }
`;

const InputGroup = styled.div`
  margin-bottom: 32px;

  &.error {
    margin-bottom: 12px;
  }

  &:last-of-type {
    margin-bottom: 40px;

    &.error {
      margin-bottom: 20px;
    }
  }
`;

const Label = styled.label`
  font-size: ${rem(14)};
  font-weight: 300;
  line-height: ${rem(20)};
  margin-bottom: 8px;

  &.error {
    color: ${Color.ritualRed};
  }

  &.error-label {
    display: none;
    font-size: ${rem(12)};
    font-weight: 300;
    line-height: ${rem(16)};
    padding-top: 4px;

    &.error {
      display: block;
    }
  }
`;

const Input = styled.input.attrs({
  required: "",
})`
  height: 44px;
  width: 100%;
  color: ${Color.ritualBlue};
  display: block;
  font-size: ${rem(16)};
  line-height: ${rem(22)};
  padding: 0 1rem;
  font-weight: 300;
  background-color: #fff;
  border-radius: 0;
  border: 1px solid ${Color.ritualSecondaryDisabledBlue};
  caret-color: ${Color.ritualBlue};

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &.error {
    border: 1px solid ${Color.ritualRed};
    color: ${Color.ritualRed};
  }
`;

const nameRegex = /^.{1,40}$/;

export class CreateFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      firstName: "",
      isFirstNameValid: false,
      lastName: "",
      isLastNameValid: false,
      email: "",
      isEmailValid: false,
      password: "",
      isPasswordValid: false,
      error: false,
      errorMessage: "",
    };
  }

  handleUserCreation(e) {
    e.preventDefault();
    const { firstName, lastName, email, password } = this.state;
    const { activeCart } = this.props;

    this.setState({
      processing: true,
    });

    createAccount(firstName, lastName, email, password)
      .then(() => {
        const currency = activeCart.currency
          ? activeCart.currency.toUpperCase()
          : "USD";

        const value = activeCart.preTaxTotal
          ? (activeCart.preTaxTotal / 100).toFixed(2)
          : "0.00";

        metrics.track("User Signup", {
          method: "email-password",
          currency,
          value,
        });
        // At this stage, the user has been created and authenticated,
        // we can now look at redirecting to the appropriate location

        const redirectUrl = this.props.redirects.login || "/approach";
        redirectReplaceHistory(redirectUrl);
      })
      .catch(error => {
        // User creation unsuccessful
        let errorMessage = intl.t(
          "authenticate.create.error",
          "Something went wrong, try again",
        );

        if (
          error.status === 422 &&
          error.errors[0] &&
          error.errors[0].source.pointer.includes("email")
        ) {
          errorMessage = intl.t(
            "authenticate.create.error-email",
            `Email {error}`,
            { error: error.errors[0].detail },
          );
        }

        this.setState({
          error: true,
          errorMessage,
        });
      })
      .finally(() => {
        this.setState({
          processing: false,
        });
      });
  }

  handleFirstNameValidation(e) {
    const updatedFirstName = e.target.value;
    const isFirstNameValid = nameRegex.test(updatedFirstName);

    // clear error on change
    this.setState({
      firstName: updatedFirstName,
      isFirstNameValid,
      error: false,
      errorMessage: "",
    });
  }

  handleLastNameValidation(e) {
    const updatedLastName = e.target.value;
    const isLastNameValid = nameRegex.test(updatedLastName);

    // clear error on change
    this.setState({
      lastName: updatedLastName,
      isLastNameValid,
      error: false,
      errorMessage: "",
    });
  }

  handleEmailValidation(e) {
    const updatedEmail = e.target.value;
    const isEmailValid = emailRegex.test(updatedEmail);

    // clear error on change
    this.setState({
      email: updatedEmail,
      isEmailValid,
      error: false,
      errorrMessage: false,
    });
  }

  handlePasswordValidation(e) {
    const passwordRegex = /^.{6,}$/;
    const updatedPassword = e.target.value;
    const isPasswordValid = passwordRegex.test(updatedPassword);

    // clear error on change
    this.setState({
      password: updatedPassword,
      isPasswordValid,
      error: false,
      errorMessage: "",
    });
  }

  render() {
    let disabled = false;

    const {
      isFirstNameValid,
      isLastNameValid,
      isEmailValid,
      isPasswordValid,
      error,
      errorMessage,
      processing,
    } = this.state;

    disabled =
      !isFirstNameValid ||
      !isLastNameValid ||
      !isEmailValid ||
      !isPasswordValid ||
      processing;
    return (
      <Form onSubmit={this.handleUserCreation.bind(this)}>
        <fieldset>
          <legend>
            <Text
              id="authenticate.create.sign-up-form"
              defaultMessage="Sign Up Form"
            />
          </legend>
          <InputGroup>
            <Label htmlFor="fname">
              <Text
                id="authenticate.create.first-name"
                defaultMessage="First Name"
              />
            </Label>
            <Input
              className="first-name-input"
              id="fname"
              type="text"
              name="fname"
              autoCapitalize="on"
              autoComplete="given-name"
              value={this.state.firstName}
              onChange={this.handleFirstNameValidation.bind(this)}
            />
          </InputGroup>

          <InputGroup>
            <Label htmlFor="lname">
              <Text
                id="authenticate.create.last-name"
                defaultMessage="Last Name"
              />
            </Label>
            <Input
              className="last-name-input"
              id="lname"
              type="text"
              name="lname"
              autoCapitalize="on"
              autoComplete="family-name"
              value={this.state.lastName}
              onChange={this.handleLastNameValidation.bind(this)}
            />
          </InputGroup>

          <InputGroup className={`${error ? "error" : ""}`}>
            <Label
              htmlFor="email"
              className={`email-label ${error ? "error" : ""}`}
            >
              <Text id="authenticate.create.email" defaultMessage="Email" />
            </Label>
            <Input
              className={`email-input ${error ? "error" : ""}`}
              id="email"
              type="text"
              name="email"
              autoCapitalize="off"
              autoComplete="email"
              value={this.state.email}
              onChange={this.handleEmailValidation.bind(this)}
            />
            <Label
              htmlFor="email"
              className={`error-label ${error ? "error" : ""}`}
            >
              {errorMessage}
            </Label>
          </InputGroup>

          <InputGroup>
            <Label htmlFor="password">
              <Text
                id="authenticate.create.password"
                defaultMessage="Password"
              />
            </Label>
            <Input
              className="password-input"
              id="password"
              type="password"
              name="password"
              autoCapitalize="off"
              autoComplete="new-password"
              value={this.state.password}
              onChange={this.handlePasswordValidation.bind(this)}
            />
          </InputGroup>

          <RitualSubmitButton
            className={`submit-button fullwidth ${disabled ? "disabled" : ""}`}
            type="submit"
            name="submit"
            value={intl.t("authenticate.create.submit", "Save and Continue")}
            title={intl.t("authenticate.create.submit", "Save and Continue")}
          />
        </fieldset>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  redirects: state.redirects,
  activeCart: cartSelectors.activeCart(state),
});

export default connect(mapStateToProps)(CreateFormComponent);
