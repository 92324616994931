import React from "react";
import styled from "styled-components";

// Utils
import { rem, Font, Color, responsive } from "../../utils/style";

// Components
import Create from "./Create";
import MagicLink from "../MagicLink";
import Text from "../Text";

// Redux & Actions
import { connect } from "react-redux";
import { updateRedirects } from "../../store/redirects/actions";

// Styled Elements
const Wrapper = styled.div.attrs({
  className: "d-flex flex-column",
})``;

const CreateHeading = styled.div`
  margin-bottom: 32px;

  ${responsive.md`
    margin-bottom: 40px;
  `}

  h1 {
    margin: 0;
    font-weight: 500;
    letter-spacing: 0px;
    margin-bottom: 8px;

    font-size: ${rem(18)};
    line-height: ${rem(28)};

    ${responsive.md`
      font-size: ${rem(20)};
      line-height: ${rem(30)};
    `}
  }

  p {
    ${Font.dutch};
    font-size: ${rem(16)};
    letter-spacing: 0px;
    line-height: ${rem(26)};
    font-weight: normal;
    margin-bottom: 0;

    ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `}
  }
`;

const LoginText = styled.p`
  ${Font.dutch};
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 0;

  width: 101%;

  ${responsive.sm`
    width: 107%;
  `}

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
    width: auto;
    margin-top: 40px;
  `}
`;

const LoginTextLink = styled(MagicLink)`
  padding-bottom: 2px;
  border-bottom: 1px solid ${Color.ritualBlue};
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.56;
  }
`;

export class CreateElements extends React.Component {
  handleLoginClick() {
    const { dispatchUpdateRedirects } = this.props;

    dispatchUpdateRedirects({
      login: process.env.GATSBY_ACCOUNT_URL || "https://account.ritual.com",
    });
  }

  render() {
    return (
      <Wrapper>
        <CreateHeading>
          <h1>
            <Text
              id="authenticate.create.create-account-heading"
              defaultMessage="Create an Account"
            />
          </h1>
          <p>
            <Text
              id="authenticate.create.create-account-p"
              defaultMessage="New here? Don’t be a stranger."
            />
          </p>
        </CreateHeading>

        <Create />

        <LoginText>
          <Text
            id="authenticate.create.already-have-account"
            defaultMessage="Already have an account?"
          />{" "}
          <LoginTextLink to="/login" onClick={this.handleLoginClick.bind(this)}>
            <Text id="authenticate.create.login" defaultMessage="Log in" />
          </LoginTextLink>
          .
        </LoginText>
      </Wrapper>
    );
  }
}

export default connect(null, {
  dispatchUpdateRedirects: updateRedirects,
})(CreateElements);
